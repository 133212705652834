import React from "react";
import {ReactComponent as ArrowBlack} from "../../../Assets/icons/black-arrow.svg"
// import {ReactComponent as RedArrow} from "../../../Assets/icons/red-arrow.svg"

import "./productsSelect.scss";
import { toast } from "react-toastify";

const ProductsSelect = ({selectedProducts, setSelectedProducts, products, setStep}) => {
    const handleProductSelect = (e, id) => {
        e.preventDefault();
        if (selectedProducts.includes(id)) {
            setSelectedProducts((pre) =>
                pre.filter((productId) => productId !== id)
            );
        } else {
            setSelectedProducts((pre) => [...pre, id]);
        }
    };
    const handleNext = () => {
        if(selectedProducts.length > 0){
            setStep(pre => pre+1)
        }else{
            toast.warn("Please select any product first.")
        }
    }
    return (
        <div className='products-select'>
            <span
                className='arrow primary-btn'
                onClick={() => setStep(pre => pre - 1)}
            >
                <ArrowBlack />
            </span>
            <h3>
                Please select product you want.
            </h3>
            {products?.length > 0 && (
                <div className='choices'>
                    {products.map((product) => (
                        <button
                            key={"product" + product.id}
                            className={`primary-btn ${
                                selectedProducts.includes(product.id)
                                    ? "selected"
                                    : ""
                            }`}
                            onClick={(e) =>
                                handleProductSelect(e, product.id)
                            }>
                            {product.title}
                        </button>
                    ))}
                </div>
            )}
            {/* <span className="continue-button" onClick={handleNext}>Continue <RedArrow/></span> */}
            <button
                className='primary-btn'
                onClick={handleNext}>
                Continue
            </button>
        </div>
    );
};

export default ProductsSelect;
