import React, { useState } from "react";
import Cross from "../../Assets/icons/close.svg";

import "./forgotPassword.scss";
import { useStateValue } from "../../StateProvider";
import { useForm } from "react-hook-form";
import { ResetPassword, SendOtp, VerifyOtp } from "../../apiCall";
import { toast } from "react-toastify";
// import { SendOtp } from "../../apiCall";

const ForgotPassword = () => {
    const [, dispatch] = useStateValue();
    const [mode, setMode] = useState("email");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    const [passwordMismatchError, setPasswordMismatchError] = useState("");
    const [token, setToken] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleForgotPassword = (status) => {
        dispatch({
            type: "SET_FORGOT_PASSWORD_MODAL_SHOW",
            status: status,
        });
    };

    const sendOtp = async (e) => {
        e.preventDefault();
        if (!email) {
            setEmailError("Email is required");
            return;
        } else {
            setEmailError("");
        }
        const Re = await SendOtp({ email: email, reason: "Forgot Password" });
        console.log(Re);

        if (Re?.data?.status[0].Error === "False") {
            setMode("otp");
        }else if(Re?.data?.status){
            toast.error(Re.data.status[0].ResponseMessage)
        }else{
            toast.error("Something went wrong, Please try again.")
        }
    };
    const verifyOtp = async (e) => {
        e.preventDefault();

        if (!otp) {
            setOtpError("Otp is required");
            return;
        } else {
            setOtpError("");
        }

        const Re = await VerifyOtp({ email, otp });
        console.log(Re);

        if (Re?.data?.status[0].Error === "False") {
            setToken(Re.data.value)
            setMode("change_password");
        }else if(Re?.data?.status){
            toast.error(Re.data.status[0].ResponseMessage)
        }else{
            toast.error("Something went wrong, Please try again.")
        }

    };
    const changePassword = async (data) => {
        if (data.newPassword !== data.confirm_password) {
            setPasswordMismatchError(
                "Password and Confirm password must be same."
            );
            return;
        }else{
            setPasswordMismatchError("")
        }

        const Re = await ResetPassword({ email: email, newPassword: data.newPassword, token: token });
        console.log(Re);

        if (Re?.data?.status[0].Error === "False") {
            toast.success("Password Changed!!")
            handleForgotPassword(false)
        }else if(Re?.data?.status){
            toast.error(Re.data.status[0].ResponseMessage)
        }else{
            toast.error("Something went wrong, Please try again.")
        }
    };
    return (
        <div className='forgot-password-main'>
            <img
                src={Cross}
                className='cross'
                alt=''
                onClick={() => handleForgotPassword(false)}
            />
            <h2>Forgot Password</h2>
            {mode === "email" && (
                <>
                    <p>Please enter your registered email address.</p>
                    <div className='input-grp'>
                        <input
                            type='email'
                            required
                            placeholder='Enter your email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <p className='form-error'>{emailError}</p>
                    </div>
                    <button
                        className='primary'
                        type='submit'
                        onClick={(e) => sendOtp(e)}>
                        Next
                    </button>
                </>
            )}
            {mode === "otp" && (
                <>
                    <p>Please enter the one time password.</p>
                    <div className='input-grp'>
                        <input
                            type='text'
                            required
                            placeholder='Enter your one time password'
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                        <p className='form-error'>{otpError}</p>
                    </div>

                    <button
                        className='primary'
                        type='submit'
                        onClick={(e) => verifyOtp(e)}>
                        Next
                    </button>
                </>
            )}
            {mode === "change_password" && (
                <>
                    <p>Please enter your new password.</p>
                    <div className='input-grp'>
                        <input
                            type='password'
                            placeholder='Enter your password'
                            {...register("newPassword", {
                                required: true,
                                pattern:
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                            })}
                        />
                        {errors.newPassword?.type === "required" && (
                            <p className='form-error'>Password is required.</p>
                        )}
                        {errors.newPassword?.type === "pattern" && (
                            <p className='form-error'>
                                Must Be Contains Uppercase, Lowercase Letters,
                                Numbers, Special Characters And Length Is
                                Greater Than 8 Character And Less Then 16
                                Character.
                            </p>
                        )}
                    </div>
                    <div className='input-grp'>
                        <input
                            type='password'
                            placeholder='Confirm your password'
                            {...register("confirm_password", {
                                required: true,
                                pattern:
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                            })}
                        />
                        {errors.confirm_password?.type === "required" && (
                            <p className='form-error'>
                                Confirm password is required.
                            </p>
                        )}
                        {errors.confirm_password?.type === "pattern" && (
                            <p className='form-error'>
                                Must Be Contains Uppercase, Lowercase Letters,
                                Numbers, Special Characters And Length Is
                                Greater Than 8 Character And Less Then 16
                                Character.
                            </p>
                        )}
                        <p className='form-error'>{passwordMismatchError}</p>
                    </div>
                    <button
                        className='primary'
                        type='submit'
                        onClick={handleSubmit(changePassword)}>
                        Change Password
                    </button>
                </>
            )}
        </div>
    );
};

export default ForgotPassword;
