import React, { useState, useEffect } from "react";
// import useWebSocket, { ReadyState } from "react-use-websocket";

import SideBar from "./components/SideBar";
import ChatWindow from "./components/ChatWindow";
import "./Chatbot.scss";
import Nav from "./components/Nav";
// import { webSocketDomain } from "../Constants";
// import { useStateValue } from "../StateProvider";
// import TemperatureSelection from "./components/TemperatureSelection";
import { GetMessageHistory } from "../../apiCall";
import { toast } from "react-toastify";
// import toast from "react-hot-toast";

const Chatbot = () => {
    const [chatHistory, setChatHistory] = useState([
        {
            message: "Hii, can i help you with USA summit 2025?",
            user: "Default",
            id: null,
            like_status: null,
        },
    ]);
    const [activeChat, setActiveChat] = useState();
    const [selectedResponseStyle, setSelectedResponseStyle] =
        useState("BALANCED");
    const [lastText, setLastText] = useState("");
    const [chats, setChats] = useState([]);
    const [showSideBar, setShowSideBar] = useState(
        window.innerWidth > 1150 ? true : false
    );
    const [showSelection, setShowSelection] = useState(
        window.innerWidth > 1150 ? true : false
    );

    // const [, dispatch] = useStateValue();

    useEffect(() => {
        setChatHistory([
            { message: "Hii, can i help you with USA summit 2025?", user: "Default" },
        ]);
    }, [activeChat]);
    // const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    //     webSocketDomain +
    //         "/" +
    //         activeChat +
    //         "/" +
    //         (sessionStorage.getItem("token") || localStorage.getItem("token"))
    // );

    // useEffect(() => {
    //     if (lastJsonMessage !== null) {
    //         console.log("FROM SOCKET", lastJsonMessage);
    //         if (lastJsonMessage.message_id === "none") {
    //             if (lastJsonMessage.answer !== "none") {
    //                 // console.log(JSON.parse(lastJsonMessage.answer))
    //                 setChatHistory((pre) => {
    //                     let temp = [...pre];
    //                     lastJsonMessage.answer.forEach((ans) => {
    //                         temp.push({message: JSON.parse(ans).human, user: "human", id: JSON.parse(ans).id, like_status: JSON.parse(ans).like_status});
    //                         temp.push({message: JSON.parse(ans).ai, user: "AI", id: JSON.parse(ans).id, like_status: JSON.parse(ans).like_status});
    //                     });
    //                     return temp;
    //                 });
    //             }
    //         } else {
    //             // if(lastJsonMessage.message_id === chatHistory?.length){
    //             //     setChatHistory(pre => [...pre, [JSON.parse(lastJsonMessage.answer)['ai'], "AI"]])
    //             // }else{
    //             //     setChatHistory(pre => pre.map((chat, indx) => indx === lastJsonMessage.message_id ? [chat[0] + JSON.parse(lastJsonMessage.answer)['ai'], chat[1]] : chat))
    //             // }
    //             // setChatHistory((pre) => [
    //             //     ...pre,
    //             //     [JSON.parse(lastJsonMessage.answer)["ai"], "AI"],
    //             // ]);

    //             console.log(JSON.parse(lastJsonMessage.answer)["ai"]);
    //             if (lastJsonMessage?.name) {
    //                 setChats((pre) =>
    //                     pre.filter((chat) => {
    //                         console.log(
    //                             chat.id,
    //                             activeChat,
    //                             chat.id === activeChat
    //                         );
    //                         if (chat.id === activeChat) {
    //                             console.log(
    //                                 "RETURNING",
    //                                 {
    //                                     ...chat,
    //                                     chat_name: lastJsonMessage?.name,
    //                                 },
    //                                 chat
    //                             );
    //                             return {
    //                                 ...chat,
    //                                 chat_name: lastJsonMessage?.name,
    //                             };
    //                         } else {
    //                             return chat;
    //                         }
    //                     })
    //                 );
    //             }
    //             setLastText({message: JSON.parse(lastJsonMessage.answer)["ai"], id: lastJsonMessage.id, user: 'AI'});
    //             console.log("last text");
    //         }
    //         if (lastJsonMessage?.connection_status === "new_connection") {
    //             dispatch({
    //                 type: "SET_IS_LOADING",
    //                 status: false,
    //             });
    //         }
    //         setIsLoading(false);
    //     }
    // }, [lastJsonMessage, dispatch, activeChat]);

    // const connectionStatus = {
    //     [ReadyState.CONNECTING]: "Connecting",
    //     [ReadyState.OPEN]: "Open",
    //     [ReadyState.CLOSING]: "Closing",
    //     [ReadyState.CLOSED]: "Closed",
    //     [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    // }[readyState];

    // useEffect(() => {
    //     console.log("IN EFFECT", connectionStatus, activeChat);
    //     if (
    //         (connectionStatus === "Open" ||
    //             connectionStatus === "Connecting") &&
    //         chatHistory?.length <= 1 &&
    //         activeChat
    //     ) {
    //         dispatch({
    //             type: "SET_IS_LOADING",
    //             status: true,
    //         });
    //     } else {
    //         dispatch({
    //             type: "SET_IS_LOADING",
    //             status: false,
    //         });
    //     }

    //     if (
    //         (connectionStatus === "Closing" || connectionStatus === "Closed") &&
    //         activeChat
    //     ) {
    //         setActiveChat();
    //     }
    // }, [connectionStatus, dispatch, activeChat, chatHistory]);

    // useEffect(() => {
    //     setLastText();
    // }, [activeChat, setLastText]);

    useEffect(() => {
        const getData = async () => {
            const re = await GetMessageHistory(activeChat);
            console.log("Message history", re);

            if (re?.response?.data?.detail) {
                toast.error(re?.response?.data?.detail);
            } else if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page");
            } else {
                if (re.data.status[0].Error === "True") {
                    toast.error(re.data.status[0].ResponseMessage);
                } else {
                    if (re.data?.value?.length > 0) {
                        setChatHistory(re.data?.value);
                    }
                }
            }
        };

        if (activeChat) {
            getData();
        }
    }, [activeChat]);

    return (
        <div className='home-main'>
            {(showSelection || showSideBar) && (
                <div
                    className={`home-backdrop ${
                        showSelection || showSideBar ? "show-backdrop" : ""
                    }`}
                    onClick={() => {
                        setShowSelection(false);
                        setShowSideBar(false);
                    }}></div>
            )}
            <SideBar
                activeChat={activeChat}
                setActiveChat={setActiveChat}
                setSelectedResponseStyle={setSelectedResponseStyle}
                setLastText={setLastText}
                chats={chats}
                setChats={setChats}
                showSideBar={showSideBar}
                setShowSideBar={setShowSideBar}
            />
            <div className='nav-chat-container'>
                {/* <Nav /> */}
                <ChatWindow
                            chatHistory={chatHistory}
                            setChatHistory={setChatHistory}
                            lastText={lastText}
                            setLastText={setLastText}
                            activeChat={activeChat}
                            activeChatObj={chats.filter(
                                (chat) => chat?.id === activeChat
                            )}
                            setChats={setChats}
                        />
            </div>
            {/* <TemperatureSelection
                id={activeChat}
                selectedResponseStyle={selectedResponseStyle}
                setSelectedResponseStyle={setSelectedResponseStyle}
                showSelection={showSelection}
                setShowSelection={setShowSelection}
            /> */}
        </div>
    );
};

export default Chatbot;
