import React, { useState } from "react";

import "./createSupportTicket.scss";
import { ReactComponent as Cross } from "../../../Assets/icons/close.svg";
import { useStateValue } from "../../../StateProvider";
import { CreateSupportTickets } from "../../../apiCall";
import { toast } from "react-toastify";
// import { Dropdown } from "react-bootstrap";

const CreateSupportTicket = ({ setTickets }) => {
  const [, dispatch] = useStateValue();
  const hideCreateSupportTicketModal = () => {
    dispatch({
      type: "SET_CREATE_SUPPORT_TICKET_MODAL_SHOW",
      status: false,
    });
  };

  const [problem, setProblem] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const Re = await CreateSupportTickets({ problem });
    console.log(Re);
    
    if (Re?.data?.status[0].Error === "False") {
      setTickets((pre) => [...pre, Re.data.value]);
      hideCreateSupportTicketModal();
    } else {
      if (Re?.data?.status) {
        toast.error(Re?.data?.status[0].ResponseMessage);
      } else {
        toast.error("Something went wrong, Please try agian.");
      }
    }
  };
  return (
    <div className="create-support-ticket-main">
      <Cross onClick={hideCreateSupportTicketModal} className="cross" />
      <div className="heading">
        <h3>Create Support Ticket</h3>
        <p>Fill up all the information here, then click submit button</p>
      </div>
      <form>
        <label htmlFor="problem">Describe the problem</label>
        <textarea
          placeholder="Write a problem"
          value={problem}
          onChange={(e) => setProblem(e.target.value)}
        ></textarea>
        {/* <Dropdown>
          <Dropdown.Toggle variant="light" id="dropdown-basic" size="sm">
            Dropdown 1
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        
        <Dropdown>
          <Dropdown.Toggle variant="light" id="dropdown-basic" size="sm">
            Dropdown 2
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <button className="primary" onClick={(e) => handleSubmit(e)}>
          Save
        </button>
      </form>
    </div>
  );
};

export default CreateSupportTicket;
