import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./ProtectedRoutes";

import Home from "./views/Home/Home";
import "./App.scss";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
// import Products from "./views/Products/Products";
import ProductsAndService from "./views/ProductsAndService/ProductsAndService";
import Products from "./views/ProductsAndService/Components/Products";
import ProductDetails from "./views/ProductDetails/ProductDetails";
import CaseStudyDetails from "./views/CaseStudyDetails/CaseStudyDetails";
import ScheduleCall from "./views/ScheduleCall/ScheduleCall";
import Cart from "./views/Cart/Cart";
import Dashboard from "./views/Dashboard/Dashboard";
import ContactUs from "./views/ContactUs/ContactUs";
import AboutUs from "./views/AboutUs/AboutUs";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./views/TermsOfUse/TermsOfUse";
import Orders from "./views/Orders/Orders";
import Welcome from "./views/Welcome/Welcome";
import RefundPolicy from "./views/RefundPolicy/RefundPolicy";
import Feedback from "./views/Feedback/Feedback";
import Chatbot from "./views/Chatbot/Chatbot";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Home />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/about-us"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <AboutUs />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/chat-bot"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Chatbot />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/products-and-service"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <ProductsAndService />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/products/:id"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Products />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/product-details/:id"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <ProductDetails />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/schedule-call"
            element={
              <ProtectedRoute>
                <ScheduleCall />
              </ProtectedRoute>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/case-study/:id"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <CaseStudyDetails />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/cart"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Cart />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/orders"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Orders />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/contact-us"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <ContactUs />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/feedback"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Feedback />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <>
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/privacy-policy"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <PrivacyPolicy />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/refund-policy"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <RefundPolicy />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/terms-of-use"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <TermsOfUse />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/welcome"
            element={
              <>
                <ProtectedRoute>
                  <Header showOptions={false} />
                  <Welcome />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
      </BrowserRouter>
      {window.screen.width < 750 ? (
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="light"
        />
      ) : (
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="light"
        />
      )}
    </div>
  );
}

export default App;
