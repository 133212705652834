import React, { useEffect, useState } from "react";
import Logo from "../../Assets/icons/logo.svg";
import Cart from "../../Assets/icons/cart.svg";
import Menu from "../../Assets/icons/menu-icon.svg";
import User from "../../Assets/icons/user.svg";
import Dashboard from "../../Assets/icons/dashboard.svg";
import Order from "../../Assets/icons/orders.svg";
import Edit from "../../Assets/icons/edit.svg";
import LogoutIcon from "../../Assets/icons/log-out.svg";
import { ReactComponent as DownArrow } from "../../Assets/icons/downArror.svg";
import WhatsApp from "../../Assets/icons/whatsapp-filled.svg";
import "./header.scss";
import { Link, useNavigate } from "react-router-dom";
import MenuOffCanvas from "./MenuOffCanvas";
import { useStateValue } from "../../StateProvider";
import { ForgotPasswordModal, LoginModal } from "../../Modals";

const Header = ({ showOptions = true }) => {
  const [{ isLoggedIn }, dispatch] = useStateValue();
  const [loginFormType, setLoginFormType] = useState("signin");
  const [isUserOptionsVisible, setIsUserOptionsVisible] = useState(false);
  const navigate = useNavigate();
  const handleNavCanvas = () => {
    dispatch({
      type: "SET_NAV_CANVAS_SHOW",
      status: true,
    });
  };

  const openLoginForm = (status) => {
    dispatch({
      type: "SET_LOGIN_MODAL_SHOW",
      status: status,
    });
  };

  const handleLogOut = () => {
    setIsUserOptionsVisible(false);
    sessionStorage.clear();
    localStorage.clear();
    dispatch({
      type: "SET_USER_LOGGED_IN",
      status: false,
    });
    sessionStorage.setItem("last_location", "");
    navigate("/");
  };

  useEffect(() => {
    if (isUserOptionsVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isUserOptionsVisible]);
  return (
    <nav className="header-main">
      {isUserOptionsVisible && (
        <div
          className="back-drop"
          onClick={() => setIsUserOptionsVisible(false)}
        ></div>
      )}
      <Link to="/">
        <img src={Logo} alt="" />
      </Link>
      <div className="menu">
        {showOptions && (
          <>
            <Link to="/" className="menu-item">
              Home
            </Link>
            <Link
              to="/about-us"
              className="menu-item"
              onClick={() => sessionStorage.setItem("scroll_about", 0)}
            >
              About Us
            </Link>
            <Link to="/products-and-service" className="menu-item">
              Products
            </Link>
            <Link to="/contact-us" className="menu-item">
              Contact Us
            </Link>
            <Link to="/feedback" className="menu-item">
              Feedback
            </Link>
            {isLoggedIn === false && (
              <button
                className="primary-btn"
                onClick={() => openLoginForm(true)}
              >
                Login
              </button>
            )}
            {isLoggedIn && (
              <Link to={"/cart"}>
                <img src={Cart} alt="" />
              </Link>
            )}
            {isLoggedIn && (
              <div className="user">
                <img
                  src={User}
                  alt=""
                  onClick={() => setIsUserOptionsVisible(true)}
                />
                <p onClick={() => setIsUserOptionsVisible(true)}>
                  {localStorage.getItem("name") ||
                    sessionStorage.getItem("name")}
                </p>
                <DownArrow onClick={() => setIsUserOptionsVisible(true)} />

                {isUserOptionsVisible && (
                  <div className="user-options">
                    <Link to={"/dashboard"} className="option">
                      <img src={Dashboard} alt="" /> <p>Dashboard</p>
                    </Link>
                    <Link to={"/orders"} className="option">
                      <img src={Order} alt="" /> <p>My Orders</p>
                    </Link>
                    <Link to={"/dashboard?editprofile=true"} className="option">
                      <img src={Edit} alt="" /> <p>Edit Profile</p>
                    </Link>
                    <Link className="option" onClick={() => handleLogOut()}>
                      <img src={LogoutIcon} alt="" /> <p>Log Out</p>
                    </Link>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div className="mobile-icons">
        {isLoggedIn && showOptions && (
          <Link to={"/cart"}>
            <img src={Cart} alt="" />
          </Link>
        )}
        {showOptions && <img src={Menu} alt="" onClick={handleNavCanvas} />}
      </div>

      <a href="http://wa.me/918584948441" target="__blank" className="whatsapp">
        <img src={WhatsApp} alt="Whats app" />
      </a>

      <MenuOffCanvas />
      <LoginModal
        loginFormType={loginFormType}
        setFormType={setLoginFormType}
      />
      <ForgotPasswordModal />
    </nav>
  );
};

export default Header;
