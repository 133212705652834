//  export const domainName = "http://142.93.223.117:6060";
export const domainName = "https://www.beta.admin.aapkacfo.com";
// export const domainName = "http://localhost:8000";  

export const premium_price = 1000;
export const googleClinetId =
  "259773608711-skcqvb47r7l6bok4snffji15sug9c29h.apps.googleusercontent.com";

export const STALE_TIME = 0 // Data is considered fresh (in milliseconds)
export const CACHE_TIME = 7200000 // Data will be cached for 2 hour (in milliseconds)
export const api = {
  login: "/api/login",
  basic_details: "/api/add-basic-details",
  google_login: "/api/verify/google_access_token",
  isEmailUsed: "/api/isEmailPhoneNumberAvailable",
  clientSignup: "/api/signup/client",
  categoriesList: "/api/products/category-list",
  productsByCategory: "/api/products/products-by-category",
  allProducts: "/api/products/all-products",
  productDetails: "/api/products/product-details",
  sessionQuestions: "/api/products/session-questions",
  cartQuestions: "/api/products/cart-questions",
  caseStudy: "/api/case-study",
  inquiry: "/api/inquiry",
  inquiryMedia: "/api/inquiry/add-media",
  // service_details: "/api/services/",
  support_ticket: "/api/support-ticket",
  // notification: "/api/notifications",
  // user: "/api/user",
  // update_notification: "/api/update-notifications",
  update_user: "/api/update-user",
  update_profile_img: "/api/update-profile-img",
  upload_documents: "/api/upload-documents",
  active_services: "/api/active-services",
  all_activated_services: "/api/active-services/all",
  // session_answers: "/api/answers",
  cart: "/api/cart",
  otp: "/api/otp",
  verify_otp: "/api/verify-otp",
  reset_password: "/api/resetPassword",
  change_password: "/api/ChangePassword",
  coupon_code_available: "/api/products/get-coupon-detail",
  payment: "/api/payment",
  complete_payment: "/api/payment/complete-payment",
  contact: "/api/contact",
  home: "/api/home",
  clients: "/api/home/clients",
  clientReviews: "/api/home/client-reviews",
  about: "/api/about",
  terms: "/api/terms-of-use",
  privacy: "/api/privacy-policy",
  refund: "/api/refund-policy",
  feedback: "/api/feedback",
  create_chat: "/api/chat",
  like_status: "/api/chat/like-status",
  generate_chat_name: "/api/chat/generate-chat-name",
};
