import { toast } from "react-toastify";
import { InitiatePayment, CompletePayment } from "../apiCall";
// import { CreateOrder, CreatePaymentOrder } from "../../apiCall";

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

export async function displayRazorpay(amount, is_premium = false, coupon_code=null, original_amount=null, total_discount=null, setShowLoader=null) {
    const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }

    // creating a new order
    //   const token = sessionStorage.getItem("token");
    if(setShowLoader){
        setShowLoader(true)
    }
    const result = await InitiatePayment({
        amount: amount,
        is_premium: is_premium,
        coupon_code: coupon_code,
        original_amount: original_amount, 
        total_discount: total_discount,
    });
    console.log(result);
    if(setShowLoader){
        setShowLoader(false)
    }
    const order = result?.data["order"];
    
    if (result?.data?.status[0].Error === "True") {
        toast.error(result.data.status[0].Message);
        return;
    }

    // Getting the order details back

    console.log(order);
    const options = {
        key: order["razorpay_merchant_key"], // Enter the Key ID generated from the Dashboard
        amount: order["razorpay_amount"],
        currency: order["currency"],
        name: "Aapka CFO",
        description: "Test Transaction",
        order_id: order["razorpay_order_id"],
        handler: async function (response) {
            console.log(response);
            const data = {
                orderCreationId: order["razorpay_order_id"],
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
            };

            const orderRe = await CompletePayment({
                order_id: data.razorpayOrderId,
                payment_id: data.razorpayPaymentId,
            });
            console.log(orderRe)

            if (orderRe?.data["status"][0]["Error"] === "True") {
                toast.error(orderRe?.data["status"][0]["ResponseMessage"]);

                return;
              } else {
                if(is_premium){
                  toast.success("Payment Completed, Please schedule a call again.")
                  // localStorage.getItem('token') ? localStorage.setItem('is_premium_user', true) : sessionStorage.setItem('is_premium_user', true)
                }else{
                  toast.success("Order Created Successfully.");
                  window.location.href = "/dashboard";
                }
            }
        },
        prefill: {
            name: "Aapka CFO",
            email: "aapkacfo@example.com",
            contact: "9999999999",
        },
        notes: {
            address: "Aapka CFO Corporate Office",
        },
        theme: {
            color: "#e31e24",
        },
    };

    console.log(options);
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
}
