import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./dashboard.scss";
// import { useStateValue } from "../../StateProvider";
import { ReactComponent as Logo } from "../../Assets/icons/logo.svg";
import { ReactComponent as DashboardIcon } from "../../Assets/icons/home.svg";
import { ReactComponent as MessageIcon } from "../../Assets/icons/message.svg";
import { ReactComponent as Edit } from "../../Assets/icons/edit.svg";
import { ReactComponent as Flag } from "../../Assets/icons/flag.svg";
import { ReactComponent as LogoutIcon } from "../../Assets/icons/log-out.svg";
// import Profile from "../../Assets/images/profile.png";
import MyDashboard from "./components/MyDashboard";
import EditProfile from "./components/EditProfile";
import Message from "./components/Message";
import Support from "./components/Support";
import Header from "../../Components/Header/Header";
import { useStateValue } from "../../StateProvider";

const Dashboard = () => {
    const navigateTo = useNavigate();

    const [searchParams] = useSearchParams();

    const [showUserOptions, setShowUserOptions] = useState(false);

    const [currentPart, setCurrentPart] = useState("dashboard");
    const [, dispatch] = useStateValue();

    useEffect(() => {
        window.scrollTo(0, 0);
        setShowUserOptions(false);
    }, [currentPart]);

    // const changeCurrentPart = (part) => {
    //   console.log("called");
    //   setShowUserOptions(false);
    //   setCurrentPart(part);
    // };

    // const setDashboardSidePanelModalShow = (status) => {
    //   dispatch({
    //     type: "SET_DASHBOARD_SIDE_PANEL_MODAL_SHOW",
    //     status: status,
    //   });
    // };

    useEffect(() => {
        if (showUserOptions) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "scroll";
        }
    }, [showUserOptions]);

    useEffect(() => {
        if (searchParams.get("editprofile")) {
            setCurrentPart("edit-profile");
        } 
        // else if (searchParams.get("message")) {
        //     setCurrentPart("message");
        // }
        else if (searchParams.get("support")) {
            setCurrentPart("support");
        } else {
            setCurrentPart("dashboard");
        }
        window.scrollTo(0, 0);
    }, [searchParams]);

    const handleLogOut = () => {
        sessionStorage.clear();
        localStorage.clear();
        dispatch({
            type: "SET_USER_LOGGED_IN",
            status: false,
        });
        navigateTo("/");
    };

    return (
        <div className='dashboard-main'>
            <Header />
            {/* For Mobile View  */}
            <div className='dashboard-body'>
                <div className='side-panel'>
                    <Logo onClick={() => navigateTo("/")} />
                    <div
                        className='profile'
                        onClick={() =>
                            navigateTo("/dashboard?editprofile=true")
                        }>
                        <div className='profile-info'>
                            {/* <img
                                src={profile_img ? profile_img : Profile}
                                alt=''
                            /> */}
                            <div className='details'>
                                <p className='bold'>
                                    {localStorage.getItem("name") ||
                                        sessionStorage.getItem("name")}
                                </p>
                                <p>
                                    {localStorage.getItem("email") ||
                                        sessionStorage.getItem("email")}
                                </p>
                            </div>
                        </div>

                        <span className='edit'>
                            <Edit />
                        </span>
                    </div>
                    <div className='items'>
                        <div
                            className={`item-container ${
                                currentPart === "dashboard" ? "current" : ""
                            }`}
                            onClick={() => navigateTo("/dashboard")}>
                            <span className='item-icon'>
                                <DashboardIcon />
                            </span>
                            <span className='item-name'>Home</span>
                        </div>
                        {/* <div
                            className={`item-container ${
                                currentPart === "message" ? "current" : ""
                            }`}
                            onClick={() =>
                                navigateTo("/dashboard?message=true")
                            }>
                            <span className='item-icon'>
                                <MessageIcon />
                            </span>
                            <span className='item-name'>Message</span>
                        </div> */}
                        <div
                            className={`item-container ${
                                currentPart === "support" ? "current" : ""
                            }`}
                            onClick={() =>
                                navigateTo("/dashboard?support=true")
                            }>
                            <span className='item-icon'>
                                <Flag />
                            </span>
                            <span className='item-name'>Support Ticket</span>
                        </div>
                        <div
                            className={`item-container ${
                                currentPart === "logout" ? "current" : ""
                            }`}
                            onClick={() => handleLogOut()}>
                            <span className='item-icon logout no-stroke'>
                                <LogoutIcon />
                            </span>
                            <span className='item-name'>Log Out</span>
                        </div>
                    </div>
                </div>
                <div className='main-content'>
                    {currentPart === "dashboard" && <MyDashboard />}
                    {currentPart === "support" && <Support />}
                    {currentPart === "edit-profile" && <EditProfile />}
                    {/* {currentPart === "message" && <Message />} */}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
