import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Email } from "../../Assets/icons/mail.svg";
import { ReactComponent as Call } from "../../Assets/icons/call.svg";
import { ReactComponent as Facebook } from "../../Assets/icons/facebook.svg";
import { ReactComponent as LinkedIn } from "../../Assets/icons/linkedIn.svg";
import { ReactComponent as Twitter } from "../../Assets/icons/twitter.svg";
// import { ReactComponent as WhatsApp } from "../../Assets/icons/whatsapp.svg";
import { ReactComponent as Instagram } from "../../Assets/icons/instagram.svg";
import "./footer.scss";

const Footer = () => {
    return (
        <footer className='footer-main'>
            <div className='footer-section'>
                <h4>Contact</h4>
                <div className='contact-info'>
                    <div className='contact-no'>
                        <a href='mailto:info@aapkacfo.com' target='__blank'>
                            <Email />
                            <span>info@aapkacfo.com</span>
                        </a>
                        <a
                            href='tel:+919321822974'
                            className='phone'
                            target='__blank'>
                            <Call />
                            <span>+91 93218 22974</span>
                        </a>
                        <span className='social'>
                            <div className='logos'>
                                {/* <span className='insta'>{<Insta />}</span> */}
                                <a href="https://www.instagram.com/aapkacfo/" className='instagram' target="__blank">{<Instagram />}</a>
                                <a href="https://www.facebook.com/profile.php?id=100062852317845" className='facebook' target="__blank">{<Facebook />}</a>
                                <a href="https://www.linkedin.com/company/aapka-cfo/" className='linkedin' target="__blank">{<LinkedIn />}</a>
                                <a href="https://twitter.com/AapkaCfo" className='twitter' target="__blank">{<Twitter />}</a>
                                {/* <a className='whatsapp'>{<WhatsApp />}</a> */}
                            </div>
                        </span>
                    </div>
                    <div className='address'>
                        <h5>India</h5>
                        <p>
                            501 Solitaire 1, Opp Infinity Mall, Link Road Malad
                            (West) Mumbai - 400064
                        </p>
                    </div>
                    <div className='address'>
                        <h5>UAE</h5>
                        <p>
                            112C, 138C Building No. 280, Taweelah, Abu Dhabi,
                            United Arab Emirates
                        </p>
                    </div>
                </div>
            </div>
            <div className='footer-section'>
                <h4>About</h4>
                <div className='about-items'>
                    <Link to={"/about-us"} className='about-link'>
                        About Us
                    </Link>
                    <Link to={"/contact-us"} className='about-link'>
                        Contact Us
                    </Link>
                    <Link to={"/products-and-service"} className='about-link'>
                        Products
                    </Link>
                    <Link to={"/privacy-policy"} className='about-link'>
                        Privacy Policy
                    </Link>
                    <Link to={"/refund-policy"} className='about-link'>
                        Refund Policy
                    </Link>
                    <Link to={"/terms-of-use"} className='about-link'>
                        Terms of Use
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
