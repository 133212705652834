import React, { useEffect, useState } from 'react'
import { GetPrivacyPolicy } from "../../apiCall";
import { useQuery } from "react-query";
import { CACHE_TIME } from "../../Constants";
import { toast } from "react-toastify";

import "./privacyPolicy.scss"

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const [content, setContent] = useState();

    useQuery(["privacy-policy"], () => GetPrivacyPolicy(), {
        // staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            console.log(data);
            if (data.data?.status[0].Error === "False") {
                setContent(data.data.value);
            }
        },
        onError: (err) => {
            console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });
  return (
    <div className='privacy-policy-main'>
      {content && (
                <>
                    <h1>Privacy Policy</h1>
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                </>
            )}
    </div>
  )
}

export default PrivacyPolicy
