import React, { useEffect, useState } from "react";

import "./contactUs.scss";
import ContactImg from "../../Assets/images/contact-us.jpg";
import { ReactComponent as Checked } from "../../Assets/icons/correct.svg";
import { ReactComponent as Email } from "../../Assets/icons/mail.svg";
import { ReactComponent as Location } from "../../Assets/icons/location.svg";
import { ReactComponent as Call } from "../../Assets/icons/call-filled.svg";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { SubmitContactRequest } from "../../apiCall";
import { toast } from "react-toastify";

const ContactUs = () => {
    const [agreePolicy, setAgreePolicy] = useState(false);
    const [policyError, setPolicyError] = useState("");
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const submitForm = async (data) => {
        if (!agreePolicy) {
            setPolicyError("Please check out policy first.");
            return;
        } else {
            setPolicyError("");
        }

        const Re = await SubmitContactRequest(data);
        console.log(Re);

        if (Re.data?.status[0].Error === "False") {
            toast.success(
                "We have received your query and will get back to you soon."
            );
            reset();
        } else {
            if (Re.data?.status) {
                toast.error(Re.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='contact-main'>
            <div className='heading'>
                <h1>Contact Us</h1>
                <p>
                    At AapkaCFO, we value your feedback and are here to assist
                    you in any way we can. Whether you have questions,
                    suggestions, or just want to say hello, our team is eager to
                    hear from you. Feel free to reach out through the contact
                    form, drop us an email, or give us a call. We're dedicated
                    to providing the best support and service, and we look
                    forward to connecting with you
                </p>
            </div>
            <div className='content'>
                <img src={ContactImg} alt='' />
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className='heading'>
                        <h2>Let's start your project</h2>
                        <p>Our friendly team would love to hear from you.</p>
                    </div>
                    <div className='inputs'>
                        <div className='input-grp'>
                            <label htmlFor='name'>Name</label>
                            <input
                                type='text'
                                placeholder='Enter your name'
                                {...register("name", {
                                    required: true,
                                    maxLength: 70,
                                })}
                            />
                            {errors.name?.type === "required" && (
                                <p className='form-error'>Name is required.</p>
                            )}
                            {errors.name?.type === "maxLength" && (
                                <p className='form-error'>
                                    Length of name can not be more than 70.
                                </p>
                            )}
                        </div>
                        <div className='input-grp'>
                            <label htmlFor='email'>Email</label>
                            <input
                                type='email'
                                placeholder='Enter your email'
                                {...register("email", {
                                    required: true,
                                })}
                            />
                            {errors.email?.type === "required" && (
                                <p className='form-error'>Email is required.</p>
                            )}
                        </div>
                        <div className='input-grp'>
                            <label htmlFor='phone_number'>Phone number</label>
                            <input
                                type='text'
                                placeholder='Enter your phone number'
                                {...register("phone_number", {
                                    required: true,
                                })}
                            />
                            {errors.phone_number?.type === "required" && (
                                <p className='form-error'>
                                    Phone number is required.
                                </p>
                            )}
                        </div>
                        <div className='input-grp'>
                            <label htmlFor='message'>Message</label>
                            <textarea
                                name='message'
                                placeholder='Leave us a message'
                                {...register("message", {
                                    required: true,
                                })}></textarea>
                            {errors.message?.type === "required" && (
                                <p className='form-error'>
                                    Message field can not be empty.
                                </p>
                            )}
                        </div>
                        <div className='privacy-check'>
                            <div className='check-box-container'>
                                <span
                                    className={`check-box ${
                                        agreePolicy ? "checked" : ""
                                    }`}
                                    onClick={() =>
                                        setAgreePolicy((pre) => !pre)
                                    }>
                                    {agreePolicy && <Checked />}
                                </span>
                                <p>
                                    You agree to our friendly{" "}
                                    <Link to={"/privacy-policy"}>
                                        privacy policy.
                                    </Link>
                                </p>
                            </div>
                            <p className='form-error'>{policyError}</p>
                        </div>

                        <button type='submit' className='primary'>
                            Send message
                        </button>
                    </div>
                </form>
            </div>
            <div className='contact-info'>
                <div className='section'>
                    <Email />
                    <p className='bold title'>Email</p>
                    <a
                        href='mailto:info@aapkacfo.com'
                        target='__blank'
                        className='text'>
                        info@aapkacfo.com
                    </a>
                </div>
                <div className='section'>
                    <Location />
                    <p className='bold title'>Our Offices</p>
                    <div className='office'>
                        <p className='bold'>India</p>
                        <p className='text'>
                            501 Solitaire 1, Opp Infinity Mall, Link Road Malad
                            (West) Mumbai - 400064
                        </p>
                    </div>
                    <div className='office'>
                        <p className='bold'>UAE</p>
                        <p className='text'>
                            112C, 138C Building No. 280, Taweelah, Abu Dhabi,
                            United Arab Emirates
                        </p>
                    </div>
                </div>
                <div className='section'>
                    <Call />
                    <p className='bold title'>Phone</p>
                    <a
                        href='tel:+919321822974'
                        target='__blank'
                        className='text'>
                        +91 9321 822 974
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
