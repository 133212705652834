import React from "react";
import "./introduction.scss";
import { useForm } from "react-hook-form";
// import { ReactComponent as RedArrow } from "../../../Assets/icons/red-arrow.svg";
import CountryCodes from "../../../CountryCodes.json"
import { PostBasicDetailsDetails } from "../../../apiCall";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Introduction = ({ setStep }) => {
    const navigateTo = useNavigate()
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm({defaultValues: JSON.parse(sessionStorage.getItem("basic_details"))});

    const first_name = watch("first_name", false);
    const last_name = watch("last_name", false);
    const email = watch("email", false);
    const country_code = watch("country_code", false);
    const phone_number = watch("phone_number", false);
    const company_name = watch("company_name", false);
    const sector = watch("sector", false);

    const submitBasicDetails = async (data) => {
        console.log(data)

        const Re = await PostBasicDetailsDetails(data)
        console.log(Re)

        if (
            Re?.data?.status[0].Error === "False"
        ) {
            if(sessionStorage.getItem('last_location')){
                navigateTo(sessionStorage.getItem('last_location'))
            }else{
                navigateTo('/')
            }
        } else {
            if (Re?.data?.status[0].Error === "True") {
                toast.error(Re.data.status[0].ResponseMessage);
            }else if(Re?.response?.data?.detail){
                toast.error(Re?.response?.data?.detail);
            } else if(Re?.response?.data?.status[0].Error === "True"){
                toast.error(Re.response.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again later.");
            }
        }
    };

    return (
        <div className='introduction'>
            <h3>
                Let's start by introducing yourself!<br></br> Please provide
                your details.
            </h3>
            <form>
                <div className='input-container'>
                    <div className='input-grp'>
                        {/* <label htmlFor='name'>What is your first name?</label> */}
                        <input
                            type='text'
                            name='first_name'
                            placeholder='Write your first name'
                            className={first_name ? "active" : ""}
                            {...register("first_name", {
                                required: true,
                                maxLength: 70,
                            })}
                        />
                        {errors.first_name?.type === "required" && (
                            <p className='form-error'>
                                First name is required.
                            </p>
                        )}
                        {errors.first_name?.type === "maxLength" && (
                            <p className='form-error'>
                                Length of first name can not be more than 70.
                            </p>
                        )}
                    </div>
                    <div className='input-grp'>
                        {/* <label htmlFor='name'>What is your last name?</label> */}
                        <input
                            type='text'
                            name='last_name'
                            placeholder='Write your last name'
                            className={last_name ? "active" : ""}
                            {...register("last_name", {
                                required: true,
                                maxLength: 70,
                            })}
                        />
                        {errors.last_name?.type === "required" && (
                            <p className='form-error'>Last name is required.</p>
                        )}
                        {errors.last_name?.type === "maxLength" && (
                            <p className='form-error'>
                                Length of last name can not be more than 70.
                            </p>
                        )}
                    </div>
                </div>
                <div className='input-container'>
                    <div className='input-grp'>
                        {/* <label htmlFor='email'>What is your email?</label> */}
                        <input
                            type='email'
                            name='email'
                            className={email ? "active" : ""}
                            placeholder='Write your email'
                            {...register("email", {
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 
                                    message: "Please Enter A Valid Email!"
                                }
                            })}
                    
                        />
                        {errors.email?.type === "required" && (
                            <p className='form-error'>Email is required.</p>
                        )}
                        {errors.email?.type === "pattern" && (
                            <p className='form-error'>Invalid email id.</p>
                        )}
                    </div>
                    <div className='input-grp'>
                        {/* <label htmlFor='phone'>
                            What is your phone number?
                        </label> */}
                        <div className='call-grp'>
                            <select
                                name='country'
                                className={country_code ? "active" : ""}
                                defaultValue={'+91'}
                                {...register("country_code")}>
                                    {CountryCodes.sort().map(code => <option value={code.dial_code}>{`${code.code} (${code.dial_code})`}</option>)}
{/*                                 
                                <option value='+91'>IN</option>
                                <option value='+1'>UK</option>
                                <option value='+2'>Us</option> */}
                            </select>
                            <input
                                type='number'
                                name='phone'
                                className={phone_number ? "active" : ""}
                                placeholder='Phone Number'
                                {...register("phone_number", {
                                    required: true,
                                    minLength: 10,
                                    maxLength: 10,
                                })}
                            />
                        </div>
                        {console.log(errors)}
                        {errors.phone_number?.type === "required" && (
                            <p className='form-error'>
                                Phone number is required.
                            </p>
                        )}
                        {(errors.phone_number?.type !== "required" && errors.phone_number) && (
                            <p className='form-error'>
                                Invalid phone number.
                            </p>
                        )}
                    </div>
                </div>
                <div className='input-container'>
                    <div className='input-grp'>
                        {/* <label htmlFor='company-name'>
                            What is your company name?
                        </label> */}
                        <input
                            type='text'
                            name='company-name'
                            className={company_name ? "active" : ""}
                            placeholder='Write the name of your company'
                            {...register("company_name", {
                                required: true,
                                maxLength: 70,
                            })}
                        />
                        {errors.company_name?.type === "required" && (
                            <p className='form-error'>
                                company name is required.
                            </p>
                        )}
                        {errors.company_name?.type === "maxLength" && (
                            <p className='form-error'>
                                Length of company name can not be more than 70.
                            </p>
                        )}
                    </div>
                    <div className='input-grp'>
                        {/* <label htmlFor='sector'>
                            In which sector/field do you operate?
                        </label> */}
                        <input
                            type='text'
                            name='sector'
                            className={sector ? "active" : ""}
                            placeholder='Write what industry you work in'
                            {...register("sector", {
                                required: true,
                                maxLength: 70,
                            })}
                        />
                        {errors.sector?.type === "required" && (
                            <p className='form-error'>Name is required.</p>
                        )}
                        {errors.sector?.type === "maxLength" && (
                            <p className='form-error'>
                                Length of sector can not be more than 70.
                            </p>
                        )}
                    </div>
                </div>
                {/* <span onClick={handleSubmit(submitBasicDetails)} className='continue-button'>
                    Continue <RedArrow />
                </span> */}
                <button
                    className='primary-btn'
                    onClick={handleSubmit(submitBasicDetails)}>
                    Submit
                </button>
            </form>
        </div>
    );
};

export default Introduction;
