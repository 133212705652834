import React, { useEffect, useState } from "react";
import "./documents.scss";
import { AddItemToCart, UploadDocuments } from "../../../apiCall";
import { useStateValue } from "../../../StateProvider";
import { toast } from "react-toastify";

const Documents = ({ required_documents, id }) => {
    const [documents, setDocuments] = useState({});
    // const [error, setError] = useState({});
    const [{ isLoggedIn, cartQuestionsAnswers }, dispatch] = useStateValue();

    useEffect(() => {
        if (!required_documents || required_documents?.length === 0) {
            const addToCart = async () => {
                if (!isLoggedIn) {
                    dispatch({
                        type: "SET_DOCUMENTS_MODAL_SHOW",
                        status: false,
                    });
                    dispatch({
                        type: "SET_LOGIN_MODAL_SHOW",
                        status: true,
                    });
                    return;
                }

                const Re = await AddItemToCart({
                    id,
                    question_answers: cartQuestionsAnswers[id]
                        ? cartQuestionsAnswers[id]
                        : [],
                });
                console.log(Re);

                if (Re?.data?.status[0].Error === "False") {
                    dispatch({
                        type: "SET_DOCUMENTS_MODAL_SHOW",
                        status: false,
                    });
                    dispatch({
                        type: "SET_ADD_TO_CART_MODAL_SHOW",
                        status: true,
                    });
                } else {
                    if (Re?.data?.status) {
                        console.log(Re.data.status[0].Message);
                        if (Re.data.status[0].Message === "present") {
                            toast.success(Re?.data?.status[0].ResponseMessage);
                            dispatch({
                                type: "SET_DOCUMENTS_MODAL_SHOW",
                                status: false,
                            });
                        } else {
                            toast.error(Re?.data?.status[0].ResponseMessage);
                        }
                    } else {
                        toast.error("Something went wrong, Please try agian.");
                    }
                }
            };
            addToCart();
        }

        setDocuments((pre) => {
            const new_obj = { ...pre };
            required_documents?.forEach((doc) => {
                if (!new_obj[doc]) {
                    new_obj[doc] = undefined;
                }
            });
            return new_obj;
        });
        // setError((pre) => {
        //     const new_obj = { ...pre };
        //     required_documents?.forEach((doc) => {
        //         if (!new_obj[doc]) {
        //             new_obj[doc] = "";
        //         }
        //     });
        //     return new_obj;
        // });
    }, [required_documents, cartQuestionsAnswers, dispatch, id, isLoggedIn]);

    const handleFileChange = (doc, e) => {
        setDocuments((pre) => {
            const new_obj = { ...pre };
            new_obj[doc] = e.target.files[0];
            return new_obj;
        });
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        // let isError = false;
        let hadDocuments = false;
        required_documents.forEach((doc) => {
            if (!documents[doc]) {
                // setError((pre) => {
                //     const new_obj = { ...pre };
                //     new_obj[doc] = doc + " is required.";
                //     return new_obj;
                // });
                // isError = true;
            } else {
                formData.append(
                    "document",
                    documents[doc],
                    doc + "." + documents[doc].name.split(".").pop()
                );
                hadDocuments = true;
                // setError((pre) => {
                //     const new_obj = { ...pre };
                //     new_obj[doc] = "";
                //     return new_obj;
                // });
            }
        });

        // if (isError) {
        //     return;
        // }

        if (!isLoggedIn) {
            dispatch({
                type: "SET_LOGIN_MODAL_SHOW",
                status: true,
            });
            dispatch({
                type: "SET_DOCUMENTS_MODAL_SHOW",
                status: false,
            });
            return;
        }

        const Re = await UploadDocuments(hadDocuments ? formData : []);
        console.log(Re);
        if (Re.data?.status[0].Error === "False") {
            const cart_res = await AddItemToCart({
                id,
                question_answers: cartQuestionsAnswers[id]
                    ? cartQuestionsAnswers[id]
                    : [],
            });
            console.log(cart_res);

            if (cart_res?.data?.status[0].Error === "False") {
                dispatch({
                    type: "SET_DOCUMENTS_MODAL_SHOW",
                    status: false,
                });
                dispatch({
                    type: "SET_ADD_TO_CART_MODAL_SHOW",
                    status: true,
                });
            } else {
                if (cart_res?.data?.status) {
                    console.log(cart_res.data.status[0].Message);
                    if (cart_res.data.status[0].Message === "present") {
                        // toast.success(
                        //     cart_res?.data?.status[0].ResponseMessage
                        // );
                        dispatch({
                            type: "SET_DOCUMENTS_MODAL_SHOW",
                            status: false,
                        });
                        dispatch({
                            type: "SET_ADD_TO_CART_MODAL_SHOW",
                            status: true,
                        });
                    } else {
                        toast.error(cart_res?.data?.status[0].ResponseMessage);
                    }
                } else {
                    toast.error("Something went wrong, Please try agian.");
                }
            }
        } else {
            if (Re.data?.status) {
                toast.error(Re.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };
    return (
        required_documents && (
            <div className='documents-main'>
                <h2>
                    Before buying this service, would you please submit some
                    required documents?
                </h2>

                {required_documents?.map((doc, indx) => (
                    <div className='input-grp' key={"document" + indx}>
                        <label htmlFor={doc}>{doc}</label>
                        <input
                            type='file'
                            name=''
                            id=''
                            onChange={(e) => handleFileChange(doc, e)}
                            accept=".jpg, .jpeg, .png, .pdf"
                        />
                        {/* <p className='form-error'>{error[doc]}</p> */}
                    </div>
                ))}
                <button className='primary' onClick={handleSubmit}>
                    Next
                </button>
            </div>
        )
    );
};

export default Documents;
