import React from "react";

import "./productTable.scss";
import { useNavigate } from "react-router-dom";

const ProductTable = ({ products, type }) => {
    const navigateTo = useNavigate();
    return (
        <div className='product-table'>
            {console.log(products)}
            {products ? (
                products
                    ?.sort((prod1, prod2) => prod1.id - prod2.id)
                    .map((prod) => (
                        <div
                            key={prod.id}
                            className='product'
                            onClick={() =>
                                navigateTo(
                                    type === "products-service"
                                        ? `/products/${prod.id}`
                                        : `/product-details/${prod.id}`
                                )
                            }>
                            <h2 className='bold'>{prod.title}</h2>
                            <p>{prod.description}</p>
                        </div>
                    ))
            ) : (
                <>
                    <div className='product component-loading'></div>
                    <div className='product component-loading'></div>
                    <div className='product component-loading'></div>
                    <div className='product component-loading'></div>
                    <div className='product component-loading'></div>
                    <div className='product component-loading'></div>
                </>
            )}
        </div>
    );
};

export default ProductTable;
