import React from "react";

import "./editProfile.scss";
// import { ReactComponent as Edit } from "../../../Assets/icons/edit.svg";
import { ChangePasswordModal } from "../../../Modals";
import { useStateValue } from "../../../StateProvider";
import { useForm } from "react-hook-form";
import {
    UpdateUser,
    // UpdateUserImage
} from "../../../apiCall";
import { toast } from "react-toastify";
// import { domainName } from "../../../Constants";
// import StaticProfile from "../../../Assets/images/profile.png";

const EditProfile = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name:
                localStorage.getItem("name") || sessionStorage.getItem("name"),
            email:
                localStorage.getItem("email") ||
                sessionStorage.getItem("email"),
            country_code:
                localStorage.getItem("country_code") ||
                sessionStorage.getItem("country_code"),
            phone_number:
                parseInt(localStorage.getItem("phone_number")) ||
                parseInt(sessionStorage.getItem("phone_number")),
        },
    });

    const [, dispatch] = useStateValue();
    const showChangePasswordModal = () => {
        dispatch({
            type: "SET_CHANGE_PASSWORD_MODAL_SHOW",
            status: true,
        });
    };

    // const fileInputRef = useRef();
    // const [file, setFile] = useState();
    // const [profileImg, setProfileImg] = useState(
    //     profile_img ? profile_img : StaticProfile
    // );

    const handleFormSubmit = async (data) => {
        console.log(data);

        const Re = await UpdateUser(data);
        console.log(Re);

        if (Re.data?.status[0].Error === "False") {
            localStorage.clear();
            sessionStorage.clear();
            sessionStorage.setItem("token", Re.data.access_token);
            sessionStorage.setItem("name", Re.data.value?.name);
            sessionStorage.setItem("email", Re.data.value?.email);
            sessionStorage.setItem("profile_img", Re.data.value?.profile_img);
            sessionStorage.setItem("country_code", Re.data.value?.country_code);
            sessionStorage.setItem("phone_number", Re.data.value?.phone_number);
            toast.success("Profile Updated.");
        } else {
            if (Re.data?.status) {
                toast.error(Re.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };

    // const handleProfileChange = (e) => {
    //     setFile(e.target.files[0]);

    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         setProfileImg(reader.result);
    //     };
    //     reader.readAsDataURL(e.target.files[0]);
    // };

    // const changeProfileImg = async () => {
    //     const imgData = new FormData();
    //     imgData.append("profile_img", file);

    //     const Re = await UpdateUserImage(imgData);
    //     console.log(Re);

    //     if (Re.data?.status[0].Error === "False") {
    //         if (sessionStorage.getItem("token")) {
    //             sessionStorage.setItem(
    //                 "profile_img",
    //                 domainName + Re.data.value
    //             );
    //             setProfileImg(domainName + Re.data.value);
    //             dispatch({
    //                 type: "SET_PROFILE_IMAGE",
    //                 status: domainName + Re.data.value,
    //             });
    //         }
    //         toast.success("Profile Image Updated.");
    //     } else {
    //         if (Re.data?.status) {
    //             toast.error(Re.data.status[0].ResponseMessage);
    //         } else {
    //             toast.error("Something went wrong, Please try again.");
    //         }
    //     }
    // };
    return (
        <div className='edit-profile-main'>
            <ChangePasswordModal />
            <div className='heading'>
                <h1>Edit Profile</h1>
                <button
                    className='primary'
                    onClick={handleSubmit(handleFormSubmit)}>
                    Save Changes
                </button>
            </div>
            {/* <div className='user-info'>
                <input
                    type='file'
                    hidden
                    ref={fileInputRef}
                    onChange={(e) => handleProfileChange(e)}
                />
                <span
                    className='user-img'
                    onClick={() => fileInputRef.current.click()}>
                    <span className='edit-image'>
                        <Edit />
                    </span>
                    <img src={profileImg} alt='' />
                </span>
                <button
                    className='secondary'
                    disabled={file ? false : true}
                    onClick={changeProfileImg}>
                    Change
                </button>
            </div> */}
            <form action=''>
                <div className='group'>
                    <div className='form-group'>
                        <label htmlFor='name'>
                            Name <span>*</span>
                        </label>
                        <input
                            type='email'
                            id='name'
                            name='name'
                            placeholder='Enter your name'
                            {...register("name", {
                                required: true,
                                maxLength: 70,
                            })}
                        />
                        {errors.name?.type === "required" && (
                            <p className='form-error'>Name is required.</p>
                        )}
                        {errors.name?.type === "maxLength" && (
                            <p className='form-error'>
                                Length of name can not be more than 70.
                            </p>
                        )}
                    </div>
                </div>
                <div className='group'>
                    <div className='form-group'>
                        <label htmlFor='email'>
                            Email <span>*</span>
                        </label>
                        <input
                            type='text'
                            id='email'
                            name='email'
                            placeholder='Enter your email'
                            {...register("email", {
                                required: true,
                            })}
                        />
                        {errors.email?.type === "required" && (
                            <p className='form-error'>Email is required.</p>
                        )}
                    </div>
                </div>
                <div className='group'>
                    <div className='form-group'>
                        <label htmlFor='phone_number'>Phone Number</label>
                        <div className='call-grp'>
                            <select
                                name='country'
                                id=''
                                {...register("country_code")}>
                                <option value='+91'>IN</option>
                                <option value='+1'>UK</option>
                                <option value='+2'>US</option>
                            </select>
                            <input
                                type='number'
                                name='phone_number'
                                id=''
                                placeholder='Phone Number'
                                {...register("phone_number", {
                                    required: true,
                                })}
                            />
                        </div>
                        {errors.phone_number?.type === "required" && (
                            <p className='form-error'>
                                Phone number is required.
                            </p>
                        )}
                    </div>
                </div>
                <div className='group'>
                    <div className='form-group'>
                        <label htmlFor='password'>Password</label>
                        <input
                            type='password'
                            id='password'
                            name='password'
                            disabled
                            value='password'
                        />
                        <p onClick={showChangePasswordModal}>
                            Change Password?
                        </p>
                    </div>
                </div>
                {/* <button className='primary'>Save Changes</button> */}
            </form>
        </div>
    );
};

export default EditProfile;
