import React, { useEffect, useState } from "react";
// import productImg from "../../Assets/images/product-img.png";

import "./orders.scss";
import { GetAllActiveServices } from "../../apiCall";
import { toast } from "react-toastify";
import { domainName } from "../../Constants";

const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const Orders = () => {
    const [orders, setOrders] = useState();

    useEffect(() => {
        const getOrders = async () => {
            const Re = await GetAllActiveServices();
            console.log(Re);
            if (Re?.data?.status[0].Error === "False") {
                setOrders(Re.data.value);
            } else {
                if (Re?.data?.status) {
                    toast.error(Re?.data?.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try agian.");
                }
            }
        };
        getOrders();

        window.scrollTo(0, 0);
    }, []);
    return orders?.length > 0 ? (
        <div className='order-page'>
            <div className='order-main'>
                <div className='heading'>
                    <h2 className='bold'>Your Orders</h2>
                    <p className='bold'>{orders.length} Items</p>
                </div>
                {/* <hr /> */}
                <div className='order-items'>
                    {orders.map((item) => (
                        <div className='product-info' key={"order" + item.id}>
                            <div className='product-name'>
                                <img
                                    src={domainName + item.product_img}
                                    alt=''
                                />
                                <h3>{item.title}</h3>
                            </div>
                            <div className='category is-active'>
                                <p className='title'>Still active?</p>
                                <p>{item.is_active ? "Yes" : "No"}</p>
                            </div>
                            <div className='category puchased-time'>
                                <p className='title'>Purchased at</p>
                                <p>{`${new Date(item.create_at)
                                    .toLocaleDateString()
                                    .slice(0, 2)} ${
                                    MONTHS[new Date(item.create_at).getMonth()]
                                } ${new Date(
                                    item.create_at
                                ).getFullYear()}`}</p>
                            </div>
                            <div className='category exipre-time'>
                                {item?.expire_at && (
                                    <p className='title'>Expires at</p>
                                )}
                                {item?.expire_at && (
                                    <p>{`${new Date(item.expire_at)
                                        .toLocaleDateString()
                                        .slice(0, 2)} ${
                                        MONTHS[
                                            new Date(item.expire_at).getMonth()
                                        ]
                                    } ${new Date(
                                        item.expire_at
                                    ).getFullYear()}`}</p>
                                )}
                            </div>
                            <span className='price'>
                                <p className='amount'>₹{item.price}</p>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ) : (
        <h2 className='empty'>You haven't created any order yet!!!</h2>
    );
};

export default Orders;
