import { useEffect, useMemo, useState } from "react";


// Function to check if component is in view port or not.
export function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
  
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        ),
      [],
    );
  
    useEffect(() => {
      if(ref?.current){

        observer.observe(ref.current);
        
        return () => {
          observer.disconnect();
        };
      }
    }, [ref, observer]);
  
    return isIntersecting;
  }

export function getUniqueQuestions(products){
  const unique_question_id = new Set()
  const unique_questions = []
  products?.forEach(prod => {
      prod.session_questions?.forEach(question => {
        if(!unique_question_id.has(question.id)){
          unique_question_id.add(question.id)
          unique_questions.push(question)          
        }        
      });
  });
  return unique_questions
}