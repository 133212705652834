import React from "react";
import "./welcomeScreen.scss";
// import { ReactComponent as RedArrow } from "../../../Assets/icons/red-arrow.svg";

const WelcomeScreen = ({ setStep }) => {
    return (
        <div className='welcome-screen'>
            <h1>Welcome!</h1>
            <h3>We're glad you're here and ready to assist you.</h3>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed seLorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed se.</p> */}

            {/* <span
                className='continue-button'
                onClick={() => setStep((pre) => pre + 1)}>
                Continue <RedArrow />
            </span> */}
            <button
                className='primary-btn'
                onClick={() => setStep((pre) => pre + 1)}>
                Continue
            </button>
        </div>
    );
};

export default WelcomeScreen;
